<template>

<section class="invoice-preview-wrapper">

  <!-- Alert: No item found -->
  <b-alert
    variant="danger"
    v-if="order === null"
  >
    <h4 class="alert-heading">
      No hay información del pedido
    </h4>
    <div class="alert-body">
      NO se ha encontrado un pedido, puedes ver la 
      <b-link
        class="alert-link"
        :to="{ name: 'order-list'}"
      >
        Lista de pedidos
      </b-link>
      para validar la información.
    </div>
  </b-alert>

  <b-row
    v-if="order"
    class="invoice-preview"
  >

    <!-- Col: Left (Invoice Container) -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <!-- Header -->
        <b-card-body class="invoice-padding pb-0">

          <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

            <!-- Header: Left Content -->
            <div>
              <div class="logo-wrapper">
                <logo />
                <h2 class="text-primary invoice-logo">
                  GRUPO LOGISTICO PETRO SAS 
                </h2>
              </div>
              <h5 class="mb-2">
                ORDEN DE ENTREGA DE MERCANCIAS
              </h5>
              <p class="card-text mb-25">
                CRA 106 #15 A 25 LT 31 MZ 5BG 18 Y 19 
              </p>
              <p class="card-text mb-25">
                ZONA FRANCA BOGOTA / BODEGA DELOING
              </p>
              <p class="card-text mb-25">
                Bogotá D.C., Colombia
              </p>
              <!-- <p class="card-text mb-0">
                +57 31526233556
              </p> -->
            </div>

            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
              <h4 class="invoice-title">
                Pedido
                <span class="invoice-number"> GLP-ZFR-{{ order.id }}</span>
              </h4>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title">
                  Estado:
                </p>
                <p class="invoice-date">
                  <b-badge :variant="statusVariant(order.order_status.name)">
                    {{ order.order_status.name }}
                  </b-badge>
                </p>
              </div>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title">
                  Fecha:
                </p>
                <p class="invoice-date">
                  {{ formatDateVariant(order.created_at) }}
                </p>
              </div>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title">
                  Actualización:
                </p>
                <p class="invoice-date">
                  {{ formatDateVariant(order.updated_at) }}
                </p>
              </div>
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr class="invoice-spacing">

        <!-- Invoice Client & Payment Details -->
        <b-card-body
          v-if="order.client"
          class="invoice-padding pt-0"
        >
          <b-row class="invoice-spacing">

            <!-- Col: Invoice To -->
            <b-col
              v-if="order.destination"
              cols="12"
              xl="6"
              class="p-0"
            >
              <h6 class="mb-2">
                Cliente:
              </h6>
              <h6 class="mb-25">
                {{ order.destination.name }}
              </h6>
              <p class="card-text mb-25">
                {{ order.destination.contact_name }}
              </p>
              <p class="card-text mb-25">
                {{ order.destination.address }}, {{ order.destination.city }}
              </p>
              <p class="card-text mb-25">
                Celular: {{ order.destination.contact_phone }}
              </p>
            </b-col>

            <!-- Col: Payment Details -->
            <b-col
              xl="6"
              cols="12"
              class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
            >
              <div>
                <h6 class="mb-2">
                  Información adicional:
                </h6>
                <span class="ml-75">{{ order.additional_info }}</span>
              </div>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Invoice Description: Table -->
        <b-table-lite
          responsive
          :items="[order]"
          :fields="['referencia', 'unidades', 'cajas', 'descripcion']"
        >
          <template #cell(referencia)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.product_type.name }}
            </b-card-text>
          </template>

          <template #cell(unidades)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.quantity_products }}
            </b-card-text>
          </template>


          <template #cell(cajas)="data">
            <b-card-text class="font-weight-bold mb-25">
              <!-- {{ data.item.quantity_products/data.item.product_type.product_by_box }} -->
              {{ data.item.boxes_count }}
            </b-card-text>
          </template>

          <template #cell(peso)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.quantity_products*data.item.product_type.weight_by_unit }}Kg
            </b-card-text>
          </template>

          <template #cell(descripcion)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.product_type.description }}
            </b-card-text>
          </template>
          
        </b-table-lite>

        <!-- Invoice Description: Total -->
        <b-card-body class="invoice-padding pb-0">
          <b-row>

            <!-- Col: Sales Persion -->
            <b-col
              cols="12"
              md="6"
              class="mt-md-0 mt-3"
              order="2"
              order-md="1"
            >
              <b-card-text class="mb-0">
                <span class="font-weight-bold text-primary">Requerimientos especiales:</span>
                <span class="ml-75">{{ order.special_requirements }}</span>
              </b-card-text>
              <b-card-text 
                v-if="order.kits"
                class="mb-0 mt-2">
                <span class="text-mute">Incluye el alistamiento de kits</span>
              </b-card-text>
              <b-card-text 
                v-if="order.cases"
                class="mb-0">
                <span class="text-mute">Incluye fundas</span>
              </b-card-text>
            </b-col>

            <!-- Col: Total -->
            <!-- <b-col
              cols="12"
              md="6"
              class="mt-md-6 d-flex justify-content-end"
              order="1"
              order-md="2"
            >
              <div class="invoice-total-wrapper">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Subtotal:
                  </p>
                  <p class="invoice-total-amount">
                    $1800
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Discount:
                  </p>
                  <p class="invoice-total-amount">
                    $28
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Tax:
                  </p>
                  <p class="invoice-total-amount">
                    21%
                  </p>
                </div>
                <hr class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    Total:
                  </p>
                  <p class="invoice-total-amount">
                    $1690
                  </p>
                </div>
              </div>
            </b-col> -->
          </b-row>
        </b-card-body>

        <!-- Spacer -->
        <hr class="invoice-spacing">

        <!-- Note -->
        <b-card-body v-if="order.deliverer" class="invoice-padding pt-0">
          <span class="font-weight-bold text-primary">Conductor: </span> <br>
          <br>
          <span> Placas: {{ order.deliverer.plate }}</span> <br>
          <span> Conductor: {{ order.deliverer.name }}</span> <br>
          <span> CC: {{ order.deliverer.document_number }}</span> <br>
        </b-card-body>
      </b-card>
    </b-col>

    <!-- Right Col: Card -->
    <b-col
      cols="12"
      md="4"
      xl="3"
      class="invoice-actions"
    >
      <b-card>

        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 text-center"
        />
        <!-- Button: Send Invoice -->
        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-75"
          block
          v-b-modal.modal-change-order-status
          v-if="[5,'5'].includes(order.order_status_id)"
        >
          Generar Remisión
        </b-button>

        <!-- Button: DOwnload -->
        <b-button
          variant="outline-secondary"
          class="mb-75"
          block
          @click="downloadOrder"
          v-if="[3,5,'3','5'].includes(order.order_status_id)"
        >
          Descargar Orden
        </b-button>

        <b-button
          variant="outline-secondary"
          class="mb-75"
          block
          :to="{ name: 'order-edit', params: { order_id: orderId } }"
          v-if="[2,5,'2','5'].includes(order.order_status_id)"
        >
          Editar
        </b-button>

        <b-button
          variant="success"
          class="mb-75"
          block
          :to="{ name: 'order-products', params: { order_id: orderId } }"
          v-if="[2,3,5,'2','3','5'].includes(order.order_status_id)"
        >
          Ver Productos
        </b-button>

        <b-button
          variant="outline-success"
          class="mb-75"
          block
          :to="{ name: 'order-stowages', params: { order_id: orderId } }"
          v-if="[2,3,5,'2','3','5'].includes(order.order_status_id)"
        >
          Ver Estibas
        </b-button>

        <b-button
          v-show="!loading"
          variant="secondary"
          class="mb-75"
          block
          @click="productsByOrderExport"
          v-if="[2,3,5,'2','3','5'].includes(order.order_status_id)"
        >
          Descargar Productos (.xlsx)
        </b-button>

        <!-- <b-button
          variant="danger"
          class="mb-75"
          block
          :to="{ name: 'order-newness', params: { order_id: orderId } }"
          v-if="[2,3,5,'2','3','5'].includes(order.order_status_id)"
        >
          Ver Novedades
        </b-button>

        <b-button
          variant="warning"
          class="mb-75"
          block
          :to="{ name: 'add-newness', params: { order_id: orderId } }"
          v-if="[2,5,'2','5'].includes(order.order_status_id)"
        >
          Crear Novedad
        </b-button> -->

        <b-button
          variant="info"
          class="mb-75"
          block
          :to="{ name: 'enlistment', params: { order_id: order.id }}"
          v-if="[1,2,'1','2'].includes(order.order_status_id) && userData.user_profile_id != 4"
        >
          Iniciar / continuar alistamiento
        </b-button>

      </b-card>
    </b-col>
  </b-row>

  <b-modal
    id="modal-change-order-status"
    title="Generar remisión"
    ok-title="Generar"
    cancel-title="Cancelar"
    @ok="changeOrderStatus()"
  >
    <b-card-text>
      <h5>Importante</h5>
      <p>¿Estás seguro de generar la remisión de este pedido? Recuerda que esta acción no se puede revertir</p>
      <p>Recuerda seleccionar en el botón "Editar" si este pedido lleva kits o fundas</p>
    </b-card-text>
  </b-modal>

</section>

</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardBody, BCardHeader, BCardTitle, BCardText, BFormCheckbox, BTableLite, BBadge, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Logo from '@/@core/layouts/components/Logo.vue'
import {constants} from "@/helpers/constants"
import moment from 'moment';
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BFormCheckbox,
    BTableLite,
    BBadge,
    BAlert,
    BLink,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Logo,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      userData: JSON.parse(localStorage.getItem('userData')),
      order: null,
      loading: false,
      required,
      invoiceData: null,
      status: [{
        1: 'En alistamiento',
        2: 'Despachado',
        3: 'Cancelado',
        4: 'Finalizado',
        5: 'Recibido',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getOrder()
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'En alistamiento'      : 'light-primary',
        Despachado : 'light-success',
        Cancelado     : 'light-danger',
        Finalizado     : 'light-warning',
        Recibido      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  methods: {
    changeOrderStatus() {
      this.loading = true

      let order_status_id = 3     // Despachado
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.post(this.host + '/api/front-web/change-order-status', {
        order_id: this.orderId,
        order_status_id: order_status_id
      }, { headers }).then(response => {
        console.log('change-order-status: ', response)
        this.loading = false

        if(response.data.status) { 
          this.order.order_status_id = response.data.data.order.order_status_id
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `OK`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `Advertencia`,
              icon: 'XCircleIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
        }

      
      }).catch( err => {
        console.log(err)
      }) 
    },
    productsByOrderExport() {

      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/product-by-order-export', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'productos-pedido-' + vm.orderId + ' - ' + date + '.xlsx';
          link.click();
          
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No se ha descargado el archivo',
              icon: 'SlashIcon',
              variant: 'warning',
            },
          }) 
        }
      };
      let params = 'order_id=' + this.orderId;
      xhr.send(params)
      xhr.th

    },
    
    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-order-detail/' + this.orderId, { headers }).then(response => {
        console.log('order: ', response)

        this.order = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    downloadOrder() {
      window.print()    
    },
    saveSerial() {
      event.preventDefault()
      this.$refs.formReadSerial.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/read-product',{
            order_id: this.order.id,
            product_type_id: this.order.product_type_id,
            serial: this.serial,
            sim: this.sim,
          }, { headers }).then(response => {
            console.log('read-product: ', response)

            if(response.data.status) {
              this.serial = ''
              this.sim = ''
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              if(response.data.data.order_complete || response.data.data.finish_order) {
                this.getOrder()
              }
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

