<template>

  <b-card>

    <b-row>
      <b-col cols="12">

        <order-detail-card 
          :order-id="orderId"
          class="mt-2 pt-75"
        />

      </b-col>
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BCard } from 'bootstrap-vue'
import OrderDetailCard from './OrderDetailCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,

    OrderDetailCard
  },
  data() {
    return {
      orderId: null,
    }
  },
  created() {
    this.orderId = router.currentRoute.params.order_id
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>